import Authorized from 'app/Authorized'
import { USER_ACCESS_PERMISSIONS } from 'app/constants'
import { lazyLoadPage } from 'app/helpers'
import useSetDocumentTitle from 'app/hooks/useSetDocumentTitle'
import AuthorizedCustomerAccess from 'app/layouts/Customer/AuthorizedCustomerAccess'
import CustomerPageLayout from 'app/layouts/Customer/CustomerPageLayout'
import CustomerRootRedirect from 'app/layouts/Customer/CustomerRootRedirect'
import AuthorizedInternalLayout from 'app/layouts/Internal/AuthorizedInternalLayout'
import CatchAll from 'app/packages/core/pages/CatchAll/CatchAll'
import ForgotPassword from 'app/packages/core/pages/login/ForgotPassword/ForgotPassword'
import IosAppRedirect from 'app/packages/core/pages/login/IosAppRedirect/IosAppRedirect'
import Login from 'app/packages/core/pages/login/Login'
import { ResendInvitation } from 'app/packages/core/pages/login/ResendInvitation/ResendInvitation'
import ResetPassword from 'app/packages/core/pages/login/ResetPassword/ResetPassword'
import TokenExchange from 'app/packages/core/pages/login/TokenExchange/TokenExchange'
import { LOGIN_PATH } from 'app/packages/core/pages/login/login.constants'
import Logout from 'app/packages/core/pages/logout/Logout'
import { LOGOUT_PATH } from 'app/packages/core/pages/logout/logout.constants'
import Welcome from 'app/packages/core/pages/welcome/Welcome'
import FileUploadsDashboard from 'app/packages/fileUploads/pages/dashboard/FileUploadsDashboard'
import InternalCustomerLayout from 'app/packages/internal/InternalCustomerLayout'
import InternalHome from 'app/packages/internal/InternalHome'
import OrderAutoUpdate from 'app/packages/internal/customerConfigs/orderAutoUpdate/pages/OrderAutoUpdate'
import CustomerConfigRoutes from 'app/packages/internal/customerConfigs/routes.constants'
import CustomerSetup from 'app/packages/internal/customerSetup/pages/CustomerSetup'
import EngToolsRoutes from 'app/packages/internal/engTools/routes.constants'
import FileIngestManager from 'app/packages/internal/fileIngest/FileIngestManager'
import { InventoryResultsContextProvider } from 'app/packages/inventoryManagement/inventoryResults/context/inventoryResultsContext'
import InventoryResultsCurrent from 'app/packages/inventoryManagement/inventoryResults/pages/InventoryResultsCurrent'
import InventoryResultsPast from 'app/packages/inventoryManagement/inventoryResults/pages/InventoryResultsPast'
import OpsInsightsDashboardView from 'app/packages/opsInsights/pages/OpsInsightsDashboardView'
import Items from 'app/packages/storeSolutions/guideOrganization/pages/Items/Items'
import Sections from 'app/packages/storeSolutions/guideOrganization/pages/Sections/Sections'
import OrdersAndDeliveriesTable from 'app/packages/storeSolutions/schedules/pages/OrdersAndDeliveriesTable/OrdersAndDeliveriesTable'
import RecurringSchedulesTable from 'app/packages/storeSolutions/schedules/pages/RecurringSchedulesTable/RecurringSchedulesTable'
import StoreClosuresTable from 'app/packages/storeSolutions/schedules/pages/StoreClosuresTable/StoreClosuresTable'
import React, { ReactNode } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const DCInsights = lazyLoadPage(() =>
  import('app/packages/distributionCenter/insights/pages/Insights'),
)
const DCInsightsItemListView = lazyLoadPage(() =>
  import(
    'app/packages/distributionCenter/insights/pages/ItemListView/ItemListView'
  ),
)
const DCInsightsItemView = lazyLoadPage(() =>
  import('app/packages/distributionCenter/insights/pages/ItemView/ItemView'),
)
const DCInsightsSettings = lazyLoadPage(() =>
  import('app/packages/distributionCenter/insights/pages/Settings/Settings'),
)
const GuideOrganization = lazyLoadPage(() =>
  import(
    'app/packages/storeSolutions/guideOrganization/pages/GuideOrganization'
  ),
)
const InventoryGuide = lazyLoadPage(() =>
  import('app/packages/storeSolutions/inventoryGuide/pages/InventoryGuide'),
)
const InventoryResultsScreen = lazyLoadPage(() =>
  import(
    'app/packages/inventoryManagement/inventoryResults/pages/InventoryResultsScreen'
  ),
)
const EndingInventoryItems = lazyLoadPage(() =>
  import(
    'app/packages/inventoryManagement/inventoryResults/pages/EndingInventoryItems/EndingInventoryItems'
  ),
)
const OrderAndDeliverySchedules = lazyLoadPage(() =>
  import('app/packages/storeSolutions/schedules/pages/Schedules'),
)
const OrderGuide = lazyLoadPage(() =>
  import('app/packages/storeSolutions/orderGuide/pages/OrderGuide'),
)

const OpsInsights = lazyLoadPage(() =>
  import('app/packages/opsInsights/pages/OpsInsights'),
)

const Users = lazyLoadPage(() => import('app/packages/core/pages/users/Users'))

const Page = ({ title, children }: { title: string; children?: ReactNode }) => {
  useSetDocumentTitle(title)
  return <>{children}</>
}

const AUTHORIZED_AFRESH_ADMIN_ONLY_INTERNAL_ROUTES = [
  {
    index: true,
    element: <InternalHome />,
  },
  {
    path: 'customer-setup',
    element: <CustomerSetup />,
  },
  {
    path: ':customerKey',
    element: <InternalCustomerLayout />,
    children: [
      {
        index: true,
        path: 'users',
        element: <Users />,
      },

      {
        path: 'order-auto-update',
        element: <OrderAutoUpdate />,
      },
      {
        path: 'configs',
        element: <Outlet />,
        children: [...CustomerConfigRoutes.routes],
      },
      {
        path: 'file-ingest-manager',
        element: (
          <Authorized permission={USER_ACCESS_PERMISSIONS.DATA_UPLOAD}>
            <FileIngestManager />
          </Authorized>
        ),
      },
      {
        path: 'eng-tools',
        element: <Outlet />,
        children: [...EngToolsRoutes.routes],
      },
    ],
  },
]

const AUTHORIZED_CUSTOMER_ROUTES = [
  {
    path: ':customerKey',
    element: <CustomerPageLayout />,
    children: [
      {
        index: true,
        element: <CustomerRootRedirect />,
      },
      {
        path: 'store-operations', // Store Operations product bundle
        children: [
          {
            path: 'order-guide/*',
            element: <OrderGuide />,
          },
          {
            path: 'inventory-guide',
            element: <InventoryGuide />,
          },
          {
            path: 'schedules',
            element: <OrderAndDeliverySchedules />,
            children: [
              {
                index: true,
                element: <Navigate to="./calendar" relative="path" />,
              },
              {
                path: 'calendar',
                element: <OrdersAndDeliveriesTable />,
              },
              {
                path: 'closures',
                element: <StoreClosuresTable />,
              },
              {
                path: 'recurring',
                element: <RecurringSchedulesTable />,
              },
            ],
          },
          {
            path: 'guide-organization',
            element: <GuideOrganization />,
            children: [
              {
                index: true,
                element: <Navigate to="./sections" />,
              },
              {
                path: 'sections',
                element: <Sections />,
              },
              {
                path: 'items',
                element: <Items />,
              },
            ],
          },
        ],
      },
      {
        path: 'inventory-results',
        element: (
          <InventoryResultsContextProvider>
            <Outlet />
          </InventoryResultsContextProvider>
        ),
        children: [
          {
            element: <InventoryResultsScreen />,
            children: [
              {
                index: true,
                element: <Navigate to="./current" />,
              },
              {
                path: 'current',
                element: <InventoryResultsCurrent />,
              },
              {
                path: 'past',
                element: <InventoryResultsPast />,
              },
            ],
          },
          {
            path: ':id',
            element: <EndingInventoryItems />,
          },
        ],
      },
      {
        path: 'dc/insights',
        element: <DCInsights />,
        children: [
          {
            path: 'items',
            element: (
              <Page title="My Desk - Afresh DC Planning">
                <DCInsightsItemListView />
              </Page>
            ),
          },
          {
            path: 'items/:id',
            element: <DCInsightsItemView />,
          },
          {
            path: 'settings',
            element: (
              <Page title="Settings - Afresh DC Planning">
                <DCInsightsSettings />
              </Page>
            ),
          },
        ],
      },
      {
        path: 'insights',
        children: [
          {
            path: 'dashboards',
            element: <OpsInsights />,
          },
          {
            path: 'dashboards/:id',
            element: <OpsInsightsDashboardView />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to="/page-not-found" />,
      },
    ],
  },
]

const routerConfig = [
  // Public Routes
  {
    path: '/sso/oidc/console/*',
    element: <TokenExchange />,
  },
  {
    path: '/sso/oidc/ios/*',
    element: <IosAppRedirect />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/resend-invitation',
    element: <ResendInvitation />,
  },
  {
    path: '/reset-password/:token',
    element: <ResetPassword />,
  },
  { path: '/welcome/:token', element: <Welcome /> },
  { path: LOGIN_PATH, element: <Login /> },
  {
    path: LOGOUT_PATH,
    element: <Logout />,
  },
  {
    path: '/dashboard/*',
    element: (
      <Authorized permission={USER_ACCESS_PERMISSIONS.DATA_UPLOAD}>
        <FileUploadsDashboard />
      </Authorized>
    ),
  },
  // Legacy URLs to redirect to root
  // TODO: These are safe to remove after Feb 1, 2024
  {
    path: '/order-guide',
    element: <Navigate to="/" />,
  },
  {
    path: '/inventory-guide',
    element: <Navigate to="/" />,
  },
  {
    path: '/schedules',
    element: <Navigate to="/" />,
  },
  {
    path: '/guide-organization',
    element: <Navigate to="/" />,
  },
  {
    path: '/inventory-results',
    element: <Navigate to="/" />,
  },
  {
    path: '/dc',
    element: <Navigate to="/" />,
  },
  // Authorized Routes
  {
    path: '/internal',
    element: (
      <AuthorizedInternalLayout>
        <Outlet />
      </AuthorizedInternalLayout>
    ),
    children: AUTHORIZED_AFRESH_ADMIN_ONLY_INTERNAL_ROUTES,
  },
  {
    path: '/',
    element: (
      <AuthorizedCustomerAccess>
        <Outlet />
      </AuthorizedCustomerAccess>
    ),
    children: AUTHORIZED_CUSTOMER_ROUTES,
  },
  {
    path: '/page-not-found',
    element: <CatchAll showHeader />,
  },
]

export default routerConfig
