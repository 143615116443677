import { Button, Typography } from '@mui/material'
import styled from 'styled-components'

export const StyledFlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.typography.pxToRem(16)};
`

export const StyledCard = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral_08};
  border: 1px solid ${({ theme }) => theme.colors.neutral_05};
  border-radius: 4px;
  /* margin-bottom: ${({ theme }) => theme.typography.pxToRem(16)}; */
  padding: ${({ theme }) => theme.typography.pxToRem(16)};
  height: 100%;
`

export const StyledCardTitle = styled(Typography)`
  display: flex;
  justify-content: center; // 40px matches the collapse arrow
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: bold;
  align-items: center;

  margin-bottom: ${({ theme }) => theme.typography.pxToRem(16)};
  color: ${({ theme }) => theme.colors.secondary};
`

export const StyledListItem = styled.div`
  flex: 0 0 calc(50% - ${({ theme }) => theme.typography.pxToRem(8)});
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
`

export const StyledButtonContainer = styled.div`
  margin-top: auto; // This will push the button to the bottom
  width: 100%; // Make the container full width
`

export const StyledButton = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.neutral_08};
    font-weight: bold;
    text-transform: none;
    padding: ${({ theme }) =>
      `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`};
    width: 100%; // Make the button full width

    &:hover {
      background-color: ${({ theme }) => theme.colors.validation};
    }
  }
`

export const StyledListItemKey = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary};
`

export const StyledListItemValue = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.neutral_02};
`
