/* eslint-disable react/no-unescaped-entities */
// SideBar.tsx
import { omit, sortBy } from 'lodash-es'

import { IngestConfigCore } from '@api/__gen__/gql'
import { useQuery } from '@apollo/client'
import { Tooltip } from '@mui/material'
import { GET_INGEST_CONFIG_CORE_SCHEMA } from 'app/packages/internal/fileIngest/fragments/fileIngestFragments'
import {
  StyledCard,
  StyledCardTitle,
  StyledFlexContainer,
  StyledListItem,
  StyledListItemKey,
  StyledListItemValue,
} from 'app/packages/internal/fileIngest/styles'
import React from 'react'

interface TableInfoProps {
  groupingData: IngestConfigCore | Record<string, never>
}

export default function TableInfo({ groupingData }: TableInfoProps) {
  // GraphQL query to get the schema. We can't load directly from the GraphQL schema as those objects are not a type
  const { data: schemaData } = useQuery(GET_INGEST_CONFIG_CORE_SCHEMA)

  const getFieldDescription = (field: string): string | undefined => {
    if (!schemaData || !schemaData.__type) return undefined
    const fieldInfo = schemaData.__type.fields.find(
      (f: { name?: string }) => f.name === field,
    )
    return fieldInfo?.description
  }

  const excludedKeys = ['_id', 'id', '__typename']

  // Define the order of keys you want, which are a subset of keys in IngestConfigCore
  const keyDisplayOrder: (keyof IngestConfigCore)[] = [
    'container',
    'pathPrefix',
    'regex',
    'datetimeFormat',
    'datetimeIdx',
    'delimiter',
    'hasHeader',
    'batchTimeOffsetMin',
    'includeOffsetInBatchTime',
    'daysForwardToScan',
    'independentShards',
    'latenessThresholdDays',
    'rawSchemaEnumClass',
  ]

  const sortedEntries = sortBy(
    Object.entries(omit(groupingData, excludedKeys)),
    ([key]) => keyDisplayOrder.indexOf(key as keyof IngestConfigCore),
  )

  return (
    <StyledCard>
      <StyledCardTitle>Table Information</StyledCardTitle>
      <StyledFlexContainer>
        {sortedEntries.map(([key, value]) => (
          <Tooltip
            key={key}
            title={getFieldDescription(key) || ''}
            enterDelay={100}
            enterNextDelay={100}>
            <StyledListItem>
              <StyledListItemKey>{key}</StyledListItemKey>
              <StyledListItemValue>{String(value)}</StyledListItemValue>
            </StyledListItem>
          </Tooltip>
        ))}
      </StyledFlexContainer>
    </StyledCard>
  )
}
