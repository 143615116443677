import { UserCapabilities } from '@api/__gen__/gql'
import { useCustomers } from 'app/CustomerContext'
import { isEmpty } from 'lodash-es'
import React, { ReactElement, useContext, useMemo } from 'react'

import {
  Engineering,
  FileCopy,
  Home,
  ImportExport,
  Inventory,
  RotateRight,
  Stairs,
  Work,
} from '@mui/icons-material'
import Users from 'app/components/Icons/Products/Users'
import { USER_ACCESS_PERMISSIONS } from 'app/constants'

import Calendar from 'app/components/Icons/Calendar'
import CautionSystemStatusFilled from 'app/components/Icons/CautionSystemStatusFilled'
import Settings from 'app/components/Icons/Settings'
import { InternalLayoutContext } from 'app/layouts/Internal/AuthorizedInternalLayout'
import { URL_PATH as USERS_URL_PATH } from 'app/packages/core/pages/users/users.constants'
import { useParams } from 'react-router-dom'
import { Section } from './InternalSideNav.types'
import InternalSideNavLink from './InternalSideNavLink'

export const getNavConfig = (
  customerKey: string = '',
  customerName: string = '',
  sideNavOpen: boolean,
) => {
  const baseAdminConfig = [
    {
      title: '',
      navItems: [
        {
          icon: Home,
          permission: USER_ACCESS_PERMISSIONS.ENGINEERING_TOOLS,
          label: 'Admin home',
          route: '/internal',
        },
        {
          icon: Users,
          permission: USER_ACCESS_PERMISSIONS.USERS_MANAGE,
          label: 'Afresh admin users',
          route: `/internal/__AFRESH__${USERS_URL_PATH}`,
        },
        {
          icon: CautionSystemStatusFilled,
          permission: USER_ACCESS_PERMISSIONS.USERS_MANAGE,
          label: 'Customer Setup',
          route: `/internal/customer-setup`,
        },
      ],
    },
  ]

  if (customerKey) {
    return [
      ...baseAdminConfig,
      {
        title: sideNavOpen ? customerName : customerKey.substring(0, 3),
        navItems: [
          {
            icon: Users,
            permission: USER_ACCESS_PERMISSIONS.USERS_MANAGE,
            label: 'Users',
            route: `/internal/${customerKey}/users`,
            end: true,
          },
          {
            icon: Calendar,
            permission: USER_ACCESS_PERMISSIONS.ENGINEERING_TOOLS,
            label: 'Ad Schedules',
            route: `/internal/${customerKey}/configs/ad-schedules`,
          },
          {
            icon: Stairs,
            permission: USER_ACCESS_PERMISSIONS.ENGINEERING_TOOLS,
            label: 'Business Levels',
            route: `/internal/${customerKey}/configs/business-level-display-names`,
          },
          {
            icon: Inventory,
            permission: USER_ACCESS_PERMISSIONS.ENGINEERING_TOOLS,
            label: 'Department Bundles',
            route: `/internal/${customerKey}/configs/department-bundles`,
          },
          {
            icon: FileCopy,
            permission: USER_ACCESS_PERMISSIONS.DATA_UPLOAD,
            label: 'File Ingest Manager',
            route: `/internal/${customerKey}/file-ingest-manager`,
          },
          {
            icon: RotateRight,
            permission: USER_ACCESS_PERMISSIONS.ENGINEERING_TOOLS,
            label: 'Order Auto Update',
            route: `/internal/${customerKey}/configs/order-auto-update`,
          },
          {
            icon: Settings,
            permission: USER_ACCESS_PERMISSIONS.ENGINEERING_TOOLS,
            label: 'RDB Configurations',
            route: `/internal/${customerKey}/configs/rdb-configurations`,
          },
          {
            icon: ImportExport,
            permission: USER_ACCESS_PERMISSIONS.ENGINEERING_TOOLS,
            label: 'Unload Order Sequence',
            route: `/internal/${customerKey}/configs/unload-order-sequence`,
          },
          {
            icon: Work,
            permission: USER_ACCESS_PERMISSIONS.ENGINEERING_TOOLS,
            label: 'Workflow Availability',
            route: `/internal/${customerKey}/configs/workflow-availability/config`,
          },

          {
            icon: Engineering,
            permission: USER_ACCESS_PERMISSIONS.ENGINEERING_TOOLS,
            label: 'Eng tools',
            route: `/internal/${customerKey}/eng-tools`,
          },
        ],
      },
    ]
  }

  return baseAdminConfig
}

export default function useCustomerNav({
  isAdmin,
  capabilities,
}: {
  isAdmin: boolean
  capabilities: UserCapabilities[]
}): { sections: Section[] } {
  const { activeCustomer } = useCustomers()
  const { customerKey } = useParams()
  const { sideNavOpen } = useContext(InternalLayoutContext)

  return useMemo(() => {
    if (capabilities.length === 0) {
      return { redirectRoute: '/internal', sections: [] }
    }

    const sections = getNavConfig(
      customerKey,
      activeCustomer?.name,
      sideNavOpen,
    ).reduce((nav, section) => {
      const items = section.navItems.reduce((navItems, navItem) => {
        if (
          (isAdmin && navItem.label === 'Admin home') ||
          capabilities.includes(navItem.permission as UserCapabilities)
        ) {
          navItems.push({
            component: (
              <InternalSideNavLink
                end={navItem.end || navItem.route === '/internal'}
                icon={navItem.icon}
                key={navItem.route}
                label={navItem.label}
                route={navItem.route}
              />
            ),
            route: navItem.route,
          })
        }

        return navItems
      }, [] as { component: ReactElement; route: string }[])

      if (!isEmpty(items)) {
        nav.push({ items, title: section.title })
      }

      return nav
    }, [] as Section[])

    return {
      sections,
    }
  }, [capabilities, isAdmin, activeCustomer, customerKey, sideNavOpen])
}
