import { IngestFile } from '@api/__gen__/gql'
import {
  StyledButton,
  StyledButtonContainer,
  StyledCard,
  StyledCardTitle,
  StyledFlexContainer,
  StyledListItem,
  StyledListItemKey,
  StyledListItemValue,
} from 'app/packages/internal/fileIngest/styles'
import React from 'react'

export default function FileInfo(fileData: IngestFile) {
  const handleDownload = () => {
    if (fileData?.url) {
      window.open(fileData.url, '_blank')
    } else {
      // eslint-disable-next-line no-alert
      window.alert('Unable to download file.')
    }
  }

  const excludedKeys = ['_id', 'id', 'tableId', 'batchTime']

  return (
    <StyledCard>
      <StyledCardTitle>File Information</StyledCardTitle>
      <StyledFlexContainer>
        {Object.entries(fileData)
          .filter(([key]) => !excludedKeys.includes(key))
          .map(([key, value]) => (
            <StyledListItem key={key}>
              <StyledListItemKey>{key}</StyledListItemKey>
              <StyledListItemValue>{String(value)}</StyledListItemValue>
            </StyledListItem>
          ))}
      </StyledFlexContainer>
      <StyledButtonContainer>
        <StyledButton onClick={handleDownload}>Download File</StyledButton>
      </StyledButtonContainer>
    </StyledCard>
  )
}
