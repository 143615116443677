import {
  FileIngest,
  IngestConfigCore,
  IngestFile,
  QueryFileIngestArgs,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { FILE_INGEST_FRAGMENT } from 'app/packages/internal/fileIngest/fragments/fileIngestFragments'
import dayjs from 'dayjs'
import { isNil } from 'lodash-es'

export const GET_INGEST_FILES = gql`
  ${FILE_INGEST_FRAGMENT}
  query GetFileIngests(
    $customerId: ID!
    $minBatchTime: String!
    $maxBatchTime: String!
  ) {
    fileIngest(
      customerId: $customerId
      minBatchTime: $minBatchTime
      maxBatchTime: $maxBatchTime
    ) {
      ...fileIngestDetails
    }
  }
`

// Flattened types.
export interface FlattenedIngestFile extends IngestFile {
  tableId: string
}

// Exported query function
export default function useGetIngestFiles(
  activeCustomerKey: string,
  minBatchTime: Date = dayjs().subtract(2, 'week').toDate(),
  maxBatchTime: Date = dayjs().toDate(),
) {
  const minBatchTimeString = dayjs(minBatchTime).format('YYYY-MM-DD')
  const maxBatchTimeString = dayjs(maxBatchTime).format('YYYY-MM-DD')

  const { data, loading } = useQuery<
    { fileIngest: FileIngest[] },
    QueryFileIngestArgs
  >(GET_INGEST_FILES, {
    skip: isNil(activeCustomerKey),
    variables: {
      customerId: activeCustomerKey,
      minBatchTime: minBatchTimeString,
      maxBatchTime: maxBatchTimeString,
    },
  })

  const ingestFiles: FlattenedIngestFile[] =
    data?.fileIngest?.flatMap(
      (fileIngest: FileIngest) =>
        // Cast to `(IngestFile | null)[]` so we don't rely on `Maybe`
        ((fileIngest.ingestTable?.ingestFiles as (IngestFile | null)[]) ?? [])
          .map((file) => {
            if (!file) return null

            return {
              _id: file._id ?? '',
              path: file.path ?? '',
              sizeBytes: file.sizeBytes ?? 0,
              isIngested: file.isIngested ?? false,
              batchTime: file.batchTime ?? '',
              createdAt: file.createdAt ?? '',
              modifiedAt: file.modifiedAt ?? '',
              url: file.url ?? '',
              tableId: fileIngest.ingestTable?._id ?? '',
            }
          })
          .filter((file): file is FlattenedIngestFile => file !== null) ?? [],
    ) ?? []

  const ingestConfigCores: IngestConfigCore[] =
    data?.fileIngest?.flatMap((fileIngest: FileIngest) => {
      if (!fileIngest.ingestConfigCore) return []
      const core = fileIngest.ingestConfigCore
      return {
        __typename: 'IngestConfigCore',
        _id: core._id ?? '',
        batchTimeOffsetMin: core.batchTimeOffsetMin ?? 0,
        container: core.container ?? '',
        datetimeFormat: core.datetimeFormat ?? '',
        datetimeIdx: core.datetimeIdx ?? 0,
        daysForwardToScan: core.daysForwardToScan ?? 0,
        delimiter: core.delimiter ?? '',
        hasHeader: core.hasHeader ?? false,
        includeOffsetInBatchTime: core.includeOffsetInBatchTime ?? false,
        independentShards: core.independentShards ?? false,
        latenessThresholdDays: core.latenessThresholdDays ?? 0,
        pathPrefix: core.pathPrefix ?? '',
        rawSchemaEnumClass: core.rawSchemaEnumClass ?? '',
        regex: core.regex ?? '',
      }
    }) ?? []

  return {
    ingestFiles,
    ingestConfigCores,
    isLoading: loading,
  }
}
