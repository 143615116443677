export function formatBytes(bytes: number) {
  if (bytes === 0) return '0 Bytes'
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(bytes) / Math.log(1024))

  let num: string
  if (i === 0) {
    num = bytes.toString()
  } else {
    const value = bytes / 1024 ** i
    num = value % 1 === 0 ? value.toFixed(0) : value.toFixed(2)
  }
  // Dynamic spacing adjustment - must be 8+
  const maxNumLength = 8
  const dynamicSpacing = ' '.repeat(maxNumLength - String(num).length)

  return `${num}${dynamicSpacing}${sizes[i]}`
}
