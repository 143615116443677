import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'
import { Box } from '@mui/material'
import {
  DataGridPremium,
  GridCellParams,
  GridColDef,
  GridGroupNode,
  GridRenderCellParams,
  GridRowId,
  GridRowsProp,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { EM_DASH } from 'app/constants'
import { formatDatetime } from 'app/helpers/date'
import { formatBytes } from 'app/helpers/formatBytes'
import React, { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'

// Constants
// Havasu assumes any file under 600 bytes is empty. This recreates that behaviour.
const EMPTY_FILE_SIZE_IN_BYTES = 600
// CSS
const StyledTableContainer = styled.div`
  height: 100vh;
  display: flex;

  & .MuiDataGrid-row:hover {
    cursor: pointer;
  }

  & .MuiDataGrid-cell:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }

  & .MuiDataGrid-withBorderColor {
    border-top: none;
    border-radius: 0;
  }

  & .group-row-cell {
    font-weight: bold;
    background-color: ${({ theme }) => theme.colors.neutral_07};
  }
`

const NoResultsFound = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  padding: '20px',
})

// Types
export interface FileManagerTableProps {
  isBlobsLoading?: boolean
  rows: object[]
  onRowClick: (params: string) => void
}

export default function FileIngestManagerTable(props: FileManagerTableProps) {
  const { isBlobsLoading, rows, onRowClick: setSelectedItemState } = props

  const getCellClassName = (params: GridCellParams) => {
    if (params.rowNode.type === 'group') {
      return 'group-row-cell'
    }
    return ''
  }

  // Define columns based on grouping state
  const columns: GridColDef[] = [
    {
      field: 'tableId',
      headerName: 'Table Name (Number of Files)',
      flex: 0.15,
    },
    {
      field: '_id',
      headerName: 'File Name',
      flex: 0.45,
    },
    {
      field: 'batchTime',
      headerName: 'Batch Time',
      flex: 0.15,
    },
    {
      field: 'sizeBytes',
      headerName: 'Size (bytes)',
      flex: 0.15,
      align: 'left',
      renderCell: (params: GridRenderCellParams) => {
        return params.row.sizeBytes
          ? formatBytes(params.row.sizeBytes)
          : EM_DASH
      },
    },
    {
      field: 'createdAt',
      headerName: 'Uploaded',
      flex: 0.25,
      renderCell: (params: GridRenderCellParams) => {
        return params.row?.createdAt
          ? formatDatetime(params.row.createdAt)
          : EM_DASH
      },
    },
    {
      field: 'isIngested',
      headerName: 'Ingested',
      flex: 0.15,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === 'group') {
          return EM_DASH // Don't show icons for group rows
        } else if (params.row?.isIngested) {
          return <CheckCircleIcon style={{ color: 'green' }} />
        } else if (params.row?.sizeBytes < EMPTY_FILE_SIZE_IN_BYTES) {
          return <WarningIcon style={{ color: 'orange' }} />
        } else {
          return <CancelIcon style={{ color: 'red' }} />
        }
      },
    },
  ] as GridColDef[]

  interface ExpansionState {
    [key: GridRowId]: boolean
  }

  // We need to maintain the state of the rows that are expanded.
  // This is especially important when clicking on cells (which triggers useEffect)
  const apiRef = useGridApiRef()
  const expansionState = useRef<ExpansionState>({})

  const isGroupExpanded = useCallback(
    (node: GridGroupNode) => expansionState.current[node.id] ?? false,
    [expansionState],
  )

  useEffect(() => {
    apiRef.current.subscribeEvent('rowExpansionChange', (node) => {
      expansionState.current[node.id] = node.childrenExpanded ?? false
    })
  }, [apiRef])

  const groupingColumnId = 'tableId'
  return (
    // Double box to resolve mui-x issue.
    // https://github.com/mui/mui-x/issues/8895#issuecomment-1793433389
    <StyledTableContainer>
      <Box sx={{ flex: 1, position: 'relative' }}>
        <Box sx={{ inset: 0, position: 'absolute' }}>
          <DataGridPremium
            rows={rows as GridRowsProp}
            apiRef={apiRef}
            isGroupExpandedByDefault={isGroupExpanded}
            getRowId={(row) => row._id}
            initialState={{
              sorting: {
                sortModel: [{ field: 'createdAt', sort: 'desc' }],
              },
            }}
            rowGroupingModel={[groupingColumnId]}
            groupingColDef={{
              leafField: groupingColumnId,
              minWidth: 300,
            }}
            getCellClassName={getCellClassName}
            // Prevents duplicate columns in table.
            columnVisibilityModel={{
              [groupingColumnId]: false,
              section: false,
            }}
            slots={{
              noRowsOverlay: () => (
                <NoResultsFound>No files found.</NoResultsFound>
              ),
            }}
            columns={columns}
            loading={isBlobsLoading}
            onRowClick={(params) => {
              // params.row_id for clicking a file, params.id for clicking a group
              setSelectedItemState(params.row._id ? params.row : params.id)
            }}
          />
        </Box>
      </Box>
    </StyledTableContainer>
  )
}
