export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AuthenticationMethod: [
      'AuthenticationMethodOidc',
      'AuthenticationMethodPassword',
    ],
    CustomerUserBase: ['CustomerUser', 'User'],
    Inventory: [
      'AutoOrderInventory',
      'EndingInventory',
      'InventoryChecksInventory',
    ],
    InventoryItem: ['InventoryChecksItem', 'PeriodEndingInventoryItem'],
    InventoryWorkflowStage: [
      'WorkflowStageCategoryTaskGroupedItems',
      'WorkflowStageDeliveryStates',
      'WorkflowStageItemCounting',
    ],
    Node: [
      'AdminUser',
      'Customer',
      'CustomerUser',
      'Department',
      'DepartmentBundle',
      'Facility',
      'FacilityItem',
      'GuideStructure',
      'InventoryGuide',
      'OidcProvider',
      'OrderDeliverySchedule',
      'OrderDeliveryStoreWorkflowSchedule',
      'OrderGuide',
      'OrderGuideItem',
      'OrderableItem',
      'OrderableItemClass',
      'OrderableItemSubclass',
      'Role',
      'Store',
      'StoreClosureEvent',
      'StoreWorkflowAvailability',
      'User',
      'Workflow',
    ],
    OrderDeliveryScheduleIssue: [
      'IllogicalDeliverySequence',
      'OrderDeliveryEventOverlapsWithStoreClosure',
      'OverlappingOrderDeliveryEvents',
    ],
    OrderWorkflowStage: [
      'WorkflowStageCategoryGroupedItems',
      'WorkflowStageCategoryTaskGroupedItems',
    ],
    PastDeliveryState: ['ConfirmedDeliveryStateV2', 'EditableDeliveryStateV2'],
    ProductBundleBase: ['StoreOpsProductBundle'],
    PublicUserBase: ['AdminUser', 'CustomerUser', 'PublicUser', 'User'],
    UserBase: ['AdminUser', 'CustomerUser', 'User'],
    WorkflowStageV2: [
      'WorkflowStageCategoryGroupedItems',
      'WorkflowStageCategoryTaskGroupedItems',
      'WorkflowStageDeliveryStates',
      'WorkflowStageItemCounting',
    ],
    WorkflowTaskItemFilter: [
      'InventoryChecksWorkflowTaskItemFilter',
      'OrderWorkflowTaskItemFilter',
    ],
  },
}
export default result
