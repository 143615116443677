import { gql } from '@apollo/client'

// Define the fragment separately
export const FILE_INGEST_FRAGMENT = gql`
  fragment fileIngestDetails on FileIngest {
    ingestTable {
      _id
      ingestFiles {
        _id
        path
        sizeBytes
        batchTime
        isIngested
        createdAt
        modifiedAt
        url
      }
    }
    ingestConfigCore {
      _id
      container
      rawSchemaEnumClass
      daysForwardToScan
      includeOffsetInBatchTime
      regex
      datetimeIdx
      datetimeFormat
      pathPrefix
      delimiter
      hasHeader
      independentShards
      batchTimeOffsetMin
      latenessThresholdDays
    }
  }
`
export const GET_INGEST_CONFIG_CORE_SCHEMA = gql`
  query GetIngestConfigCoreSchema {
    __type(name: "IngestConfigCore") {
      name
      description
      fields {
        name
        description
      }
    }
  }
`
