import { IngestFile } from '@api/__gen__/gql'
import { Tooltip } from '@mui/material'
import { useCustomers } from 'app/CustomerContext'
import TableHeader from 'app/components/Tables/TableHeader'
import useGetIngestFiles, {
  FlattenedIngestFile,
} from 'app/packages/internal/fileIngest/queries/useGetIngestFiles'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import FileIngestManagerTable from './FileIngestManagerTable'
import SideBar from './sidebar/SideBar'

// CSS
const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  height: 100%;
  position: relative;
`
const StyledHeaderContainer = styled.div`
  padding-top: 0;
`
const MainContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
`

// Helper functions
function filterFile(row: FlattenedIngestFile, searchText: string) {
  if (
    row.path.toLowerCase().includes(searchText) ||
    row._id?.toLowerCase().includes(searchText) ||
    row.tableId?.toLowerCase().includes(searchText)
  ) {
    return true
  }
  return false
}

// Component
export default function FileIngestManager() {
  const { activeCustomerKey } = useCustomers()

  // State
  const [searchText, setSearchText] = useState('')
  const [selectedItem, setSelectedItem] = useState<IngestFile | string | null>(
    null,
  )

  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'week'))
  const [endDate, setEndDate] = useState(dayjs())
  const [sidebarOpen, setSidebarOpen] = useState(true)

  // Callback Functions
  const handleNavigateBackward = () => {
    const newStartDate = dayjs(startDate).subtract(2, 'week')
    const newEndDate = dayjs(endDate).subtract(2, 'week')
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const handleNavigateForward = () => {
    const newStartDate = dayjs(startDate).add(2, 'week')
    const newEndDate = dayjs(endDate).add(2, 'week')
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  // Data loaders
  const {
    ingestFiles: rows,
    ingestConfigCores,
    isLoading: isBlobsLoading,
  } = useGetIngestFiles(activeCustomerKey, startDate.toDate(), endDate.toDate())

  // No need to memoize as GraphQL will handle caching
  const filteredRows = useMemo(() => {
    return rows.filter((row) =>
      filterFile(row, searchText.toLowerCase().trim()),
    )
  }, [rows, searchText])

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen)
  }

  // Render
  return (
    <ContentWrapper>
      <MainContentContainer>
        <StyledHeaderContainer>
          <Tooltip title="Batch Time Range" placement="top" enterDelay={500}>
            <div>
              <TableHeader
                datePickProps={{
                  dateRange: {
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD'),
                  },
                  navigateForward: handleNavigateForward,
                  navigateBackward: handleNavigateBackward,
                  navigateForwardAriaLabel: 'Next period',
                  navigateBackwardAriaLabel: 'Previous period',
                }}
                itemCount={filteredRows.length}
                onSearch={setSearchText}
              />
            </div>
          </Tooltip>
        </StyledHeaderContainer>
        <FileIngestManagerTable
          isBlobsLoading={isBlobsLoading}
          rows={filteredRows}
          onRowClick={setSelectedItem}
        />
      </MainContentContainer>
      <SideBar
        selectedItem={selectedItem}
        ingestConfigCores={ingestConfigCores}
        isOpen={sidebarOpen}
        onToggle={toggleSidebar}
      />
    </ContentWrapper>
  )
}
