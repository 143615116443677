import { Info } from '@mui/icons-material'
import { List, ListItemText, Typography } from '@mui/material'
import {
  StyledButton,
  StyledButtonContainer,
  StyledCard,
  StyledCardTitle,
  StyledListItem,
} from 'app/packages/internal/fileIngest/styles'
import React from 'react'
import styled from 'styled-components'

const StyledList = styled(List)`
  padding-left: ${({ theme }) => theme.typography.pxToRem(16)};
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(16)};
`

const StyledWelcomeContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-y: auto; // Allow scrolling if content exceeds available space
`

const StyledWelcomeText = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(16)};
  color: ${({ theme }) => theme.colors.neutral_02};
  line-height: 1.6;
`

const StyledWelcomeCard = styled(StyledCard)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; // Prevent scrolling
`

export default function WelcomeTable() {
  const handleLearnMoreClick = () => {
    window.open(
      'https://www.notion.so/afresh/Develop-Table-Manager-for-integrations-129287fd508b809a80bff9cd987e1223?pvs=4',
      '_blank',
      'noopener,noreferrer',
    )
  }

  return (
    <StyledWelcomeCard>
      <StyledCardTitle variant="h5">
        Welcome to the File Ingest Manager
      </StyledCardTitle>
      <StyledWelcomeContent>
        <StyledWelcomeText>
          The File Ingest Manager is the central hub for managing and viewing
          files. Here&apos;s how to get started:
        </StyledWelcomeText>
        <StyledList>
          <StyledListItem>
            <ListItemText
              primary="Browse Files"
              secondary="Use the table on the left to navigate through your files and tables. Files are grouped by table name."
            />
          </StyledListItem>
          <StyledListItem>
            <ListItemText
              primary="View Details"
              secondary="Click on a table name to see more information. Select individual files to view their details and download options."
            />
          </StyledListItem>
          <StyledListItem>
            <ListItemText
              primary="Download Files"
              secondary="When viewing file details, you can easily download the file for local access and analysis."
            />
          </StyledListItem>
          <StyledListItem>
            <ListItemText
              primary="Monitor Ingestion"
              secondary="Check the 'Ingested' column to see which files have been successfully processed"
            />
          </StyledListItem>
        </StyledList>
        <StyledButtonContainer>
          <StyledButton startIcon={<Info />} onClick={handleLearnMoreClick}>
            Learn More About File Ingest Manager
          </StyledButton>
        </StyledButtonContainer>
      </StyledWelcomeContent>
    </StyledWelcomeCard>
  )
}
